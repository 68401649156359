<template>
  <div v-if="!isLoadingPage">
    <div v-if="permission !== null">
      <v-container
        v-if="permission.read_perm == 1"
        style="padding: 0; margin: auto; border-radius: 5px"
      >
        <v-card v-if="result !== null" elevation="0">
          <v-toolbar color="transparent" light flat prominent>
            <v-list-item two-line>
              <v-list-item-avatar size="60">
                <v-img
                  :src="result.photo"
                  lazy-src="https://cdn-icons-png.flaticon.com/512/61/61173.png"
                />
              </v-list-item-avatar>

              <v-list-item-content>
                <v-toolbar-title>
                  <v-list-item-title style="font-weight: bold; font-size: 18px">
                    {{ result.name }}
                    <v-tooltip v-if="!isEdit" bottom color="grey">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          x-small
                          text
                          icon
                          color="grey darken-2"
                          style="position: relative; top: -3px"
                          @click="detail()"
                        >
                          <v-icon small>mdi-refresh</v-icon>
                        </v-btn>
                      </template>
                      <span>refresh page</span>
                    </v-tooltip>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    ({{
                      result.department !== null ? result.department.name : ''
                    }}) - {{ result.level !== null ? result.level.name : '' }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    {{ result.nik }}
                  </v-list-item-subtitle>
                </v-toolbar-title>
              </v-list-item-content>
            </v-list-item>

            <v-spacer></v-spacer>

            <v-btn
              type="button"
              rounded
              elevation="0"
              color="transparent"
              class="red--text font-weight-bold"
              style="font-size: 12px"
              @click="close"
            >
              <v-icon small>mdi-chevron-left</v-icon>
              Kembali
            </v-btn>
            <!-- <template v-if="result.can_access_salary" v-slot:extension> -->
            <template v-slot:extension>
              <v-tabs v-model="tab" align-with-title>
                <v-tabs-slider color="cyan"></v-tabs-slider>

                <v-tab
                  style="
                    border: 1px solid #e1e1e1;
                    text-transform: capitalize;
                    color: black;
                  "
                  v-for="(item, index) in items"
                  :key="index"
                >
                  {{ item.title }}
                </v-tab>
              </v-tabs>
            </template>
          </v-toolbar>

          <!-- <v-tabs-items
            v-if="result.can_access_salary"
            class="v-tab-detail-employee"
            v-model="tab"
          > -->
          <v-tabs-items class="v-tab-detail-employee" v-model="tab">
            <v-tab-item v-for="(item, index) in items" :key="index">
              <general-information
                v-if="item.tab === 1"
                :result="result"
                :dropdown="dropdown"
                :isEdit="isEdit"
                :permission="permission"
                @cancel="detail"
                @editForm="editForm"
                @changeTab="changeTab"
              />
              <contract-detail
                v-if="item.tab === 2"
                :result="result"
                :dropdown="dropdown"
                :isEdit="isEdit"
                :permission="permission"
                :appLevel="appLevel"
                :userLevel="userLevel"
                @cancel="detail"
                @editForm="editForm"
                @changeTab="changeTab"
                @findDepartmentDropdown="dropdownDepartment"
              />
              <payroll
                v-if="item.tab === 3"
                :result="result"
                :dropdown="dropdown"
                :isEdit="isEdit"
                :permission="permission"
                :appLevel="appLevel"
                @cancel="detail"
                @editForm="editForm"
                @changeTab="changeTab"
                @watcher="triggerWatcher"
              />
              <leave-management
                v-if="item.tab === 4"
                :result="result"
                :permission="permission"
                :appLevel="appLevel"
                :userLevel="userLevel"
                @adjusmentSubmit="detail"
              />
              <permission
                v-if="item.tab === 5"
                :permission="permission"
                :appLevel="appLevel"
                :result="result"
                :userLevel="userLevel"
              />
              <attendance
                v-if="item.tab === 6"
                :result="result"
                :dropdown="dropdown"
                :permission="permission"
                :appLevel="appLevel"
                :userLevel="userLevel"
                @cancel="detail"
              />
              <loan
                v-if="item.tab === 7"
                :permission="permission"
                :appLevel="appLevel"
                :result="result"
                :userLevel="userLevel"
              />
              <cop
                v-if="item.tab === 8"
                :permission="permission"
                :appLevel="appLevel"
                :result="result"
                :userLevel="userLevel"
              />
              <div v-if="result !== null">
                <user-settings
                  v-if="item.tab === 9"
                  :result="result"
                  :permission="permission"
                  :appLevel="appLevel"
                  :userLevel="userLevel"
                />
              </div>
            </v-tab-item>
          </v-tabs-items>
          <!-- <v-card
            v-else
            elevation="0"
            style="
              margin: center;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 50vh;
              font-size: 14px;
              background: rgba(255, 255, 255);
            "
          >
            <p>YOU CANNOT ACCESS THIS DATA</p>
          </v-card> -->
        </v-card>
        <v-form ref="entryForm" @submit.prevent="submit"> </v-form>
      </v-container>
    </div>

    <v-card
      v-else
      class="mx-auto"
      width="100%"
      style="
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        position: fixed;
        top: 0;
        height: 100%;
        left: 0;
        right: 0;
        background: rgba(255, 255, 255);
      "
    >
      DATA NOT FOUND
    </v-card>
  </div>

  <div v-else>
    <v-card
      class="mx-auto"
      width="100%"
      style="
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        position: fixed;
        top: 0;
        height: 100%;
        left: 0;
        right: 0;
        background: rgba(255, 255, 255);
      "
    >
      <v-progress-circular :size="170" :width="10" color="blue" indeterminate
        >Loading...</v-progress-circular
      >
    </v-card>
  </div>
</template>
<script>
import axios from 'axios'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import GeneralInformation from './components/GeneralInformation.vue'
import ContractDetail from './components/ContractDetail.vue'
import LeaveManagement from './components/LeaveManagement.vue'
import Permission from './components/Permission.vue'
import Attendance from './components/Absence.vue'
import UserSettings from './components/UserSettings.vue'
import Payroll from './components/Payroll.vue'
import Loan from './components/Loan.vue'
import Cop from './components/Cop.vue'
import buildType from '../../../services/buildType'
const env = process.env.VUE_APP_BUILD_TYPE
export default {
  name: 'employeedetail',
  components: {
    GeneralInformation,
    ContractDetail,
    LeaveManagement,
    Permission,
    Attendance,
    UserSettings,
    Payroll,
    Loan,
    Cop
  },
  data() {
    return {
      config: require('../../../services/config.json')[env],
      hrsApi: buildType.apiURL('hrs'),
      oAuthApi: buildType.apiURL('oauth'),
      eSanqua: buildType.apiURL('esanqua'),
      build: process.env.VUE_APP_BUILD_TYPE,
      permission: null,
      appLevel: null,
      userLevel: null,
      isLoadingPage: true,
      loading: false,
      tab: null,
      isEdit: false,
      items: [
        {
          tab: 1,
          title: 'Informasi Umum'
          // title: 'Biodata Pribadi'
        },
        {
          tab: 2,
          title: 'Detail Info'
          // title: 'Info Kepegawaian'
        },
        {
          tab: 3,
          title: 'Payroll'
        },
        {
          tab: 4,
          title: 'Cuti'
        },
        {
          tab: 5,
          title: 'Izin'
        },
        {
          tab: 6,
          title: 'Presensi'
        },
        {
          tab: 7,
          title: 'Peminjaman'
        },
        {
          tab: 8,
          title: 'COP'
        },
        {
          tab: 9,
          title: 'Setting'
        }
      ],
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      wWidth: window.innerWidth,
      result: null,

      form: null,
      dropdown: {
        company: [],
        department: [],
        employee_status: [],
        employee_class: [1, 2, 3, 4, 5, 6, 7, 8],
        activation_status: [
          {
            id: 1,
            name: 'Active'
          },
          {
            id: 0,
            name: 'Inactive'
          }
        ],
        position: [],
        section: [],
        education: [
          'SD',
          'SMP',
          'SMA',
          'SMK',
          'D1',
          'D2',
          'D3',
          'D4',
          'S1',
          'S2',
          'S3'
        ],
        blood_type: ['A', 'B', 'AB', 'O', 'Rh-', 'Rh+'],
        ptkp_status: [],
        religion: [
          {
            name: 'Islam',
            id: 1
          },
          {
            name: 'Kristen',
            id: 2
          },
          {
            name: 'Hindu',
            id: 3
          },
          {
            name: 'Budha',
            id: 4
          },
          {
            name: 'Katolik',
            id: 6
          },
          {
            name: 'Konghucu',
            id: 7
          }
        ],
        attendance_status: [
          {
            id: 0,
            name: 'None'
          },
          {
            id: 1,
            name: 'IN'
          },
          {
            id: 2,
            name: 'OUT'
          }
        ],
        bpjs_template: [],
        tax_object: [],
        shift_category: []
      },
      unwatchcompany: null,
      unwatchdepartment: null,
      unwatchumr: null,
      unwatchgajipokok: null,
      unwatchtunjanganjabatan: null,
      unwatchtunjanganperumahan: null
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from
    })
  },
  computed: {
    ...mapGetters(['getUserProfile', 'getLoginPayroll'])
  },
  async mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })

    this.appLevel = this.config.application.hr.userLevel
    this.appLevel.administrator =
      this.config.application.all.userLevel.administrator
    if (this.getLoginPayroll) {
      this.verifyAccessToken()
    } else {
      this.getDataMenu()
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    ...mapMutations(['setLoginPayroll']),
    ...mapActions(['menu_access', 'getMenuId']),

    async verifyAccessToken() {
      this.isLoadingPage = true
      await axios
        .post(
          `${this.oAuthApi}otp/access_token/verify`,
          {},
          {
            headers: {
              'x-access-token': this.getLoginPayroll
            }
          }
        )
        .then((res) => {
          console.log('verify access token>>>', res)
          if (res.data.status_code == '-99') {
            this.setLoginPayroll(null)
          }
          this.getDataMenu()
          this.isLoadingPage = false
        })
        .catch((err) => {
          console.log('error>>', err)
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.isLoadingPage = false
        })
    },
    async getDataMenu() {
      this.isLoadingPage = true
      const split = this.$route.path.split('/')
      const routeModule = '/' + split[1] + '/' + split[2]
      await this.getMenuId({
        keyword: '',
        path: routeModule
      })
        .then((result) => {
          if (result.data.status_code === '00') {
            const menuId = result.data.data[0].id
            this.userLevel = this.getUserProfile.level.find(
              ({ application }) =>
                application.id === this.config.application.hr.applicationId ||
                application.id === this.config.application.all.applicationId
            )
            console.log('userLevel>>>>>', this.userLevel)
            if (this.userLevel !== null) {
              if (
                this.getUserProfile.level.find(
                  ({ id }) => id == this.appLevel.administrator
                ) !== undefined
              ) {
                this.resetPermission({
                  data: {
                    read_perm: 1,
                    create_perm: 1,
                    update_perm: 1,
                    delete_perm: 1
                  }
                })
              } else {
                this.checkMenuAccess([menuId, this.userLevel.id])
              }
            } else {
              this.resetPermission(null)
            }
          } else {
            this.isLoadingPage = false
          }
        })
        .catch((err) => {
          console.log(err)
          this.isLoadingPage = false
        })
    },
    async checkMenuAccess(payload) {
      await this.menu_access(payload)
        .then((result) => {
          console.log('menuAccess>>>>', result)
          this.resetPermission(result)
        })
        .catch((err) => {
          console.log(err)
          this.resetPermission(null)
        })
    },
    async resetPermission(val) {
      this.permission = null

      if (val !== null) {
        if (val.data !== null) {
          if (val.data.read_perm === 1) {
            this.permission = val.data

            await this.detail()
            this.initDropdown()
          }
        }
      }
      this.isLoadingPage = false
    },
    async initDropdown() {
      this.dropdownCompany()
      // this.dropdownDepartment()
      this.dropdownPosition()
      this.dropdownEmployeeStatus()
      this.dropdownPTKPStatus()
      this.dropdownTaxObject()
      this.dropdownShiftCategory()
      this.getDropdownBpjsTemplate()
    },
    onResize() {
      this.wWidth = window.innerWidth
    },
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then((r) => {
            resolve(r)
          })
      })
    },
    changeTab(v) {
      this.tab = v
    },
    editForm() {
      if (this.permission.create_perm && this.permission.update_perm == 1) {
        this.dropdownDepartment()
        if (this.isEdit) {
          this.triggerWatcher('stop')
        }
        if (!this.isEdit) {
          this.triggerWatcher('start')
        }
        setTimeout(() => {
          this.isEdit = !this.isEdit
        }, 50)
      }
    },
    async detail() {
      this.loading = true
      this.result = null
      await axios
        .get(`${this.hrsApi}employee/detail/${this.$route.params.id}`, {
          headers: {
            'x-access-token': this.getLoginPayroll
          }
        })
        .then((res) => {
          console.log('get detail employee>>', res)
          if (res.data.status_code !== '-99') {
            let company = null
            let department = null
            let section = null
            let unit = null
            if (res.data.data.department !== null) {
              department = {
                id: res.data.data.department.id,
                name: res.data.data.department.name
              }
              if (res.data.data.department.section !== null) {
                section = {
                  id: res.data.data.department.section.id,
                  name: res.data.data.department.section.name
                }
              }
              if (res.data.data.department.unit !== null) {
                unit = {
                  id: res.data.data.department.unit.id,
                  name: res.data.data.department.unit.name
                }
              }
            }

            if (res.data.data.company !== null) {
              company = {
                id: Number(res.data.data.company.plant_id),
                name: res.data.data.company.name
              }
            }

            this.result = {
              address: res.data.data.address,
              birth_place: res.data.data.birth_place,
              birth_date: res.data.data.birth_date,
              blood_type: res.data.data.blood_type,
              bpjs_no: res.data.data.bpjs_no,
              bpjs_tk_no: res.data.data.bpjs_tk_no,
              card_no: res.data.data.card_no,
              company: company,
              contract_date: res.data.data.contract_date,
              contract_month: res.data.data.contract_month,
              department: department,
              department_id: res.data.data.department_id,
              domicile_address: res.data.data.domicile_address,
              education_level: res.data.data.education_level,
              education_major: res.data.data.education_major,
              email: res.data.data.email,
              employee_status: res.data.data.employee_status,
              expire_contract_date: res.data.data.expire_contract_date,
              expire_leave_n_1: res.data.data.expire_leave_n_1,
              gender: res.data.data.gender,
              gol: res.data.data.gol,
              id: res.data.data.id,
              join_date: res.data.data.join_date,
              level: res.data.data.level,
              level_id: res.data.data.level_id,
              mariage_status: res.data.data.mariage_status,
              mobile_phone_no: res.data.data.mobile_phone_no,
              name: res.data.data.name,
              nik: res.data.data.nik,
              no_identitas: res.data.data.no_identitas,
              npwp_no: res.data.data.npwp_no,
              photo: res.data.data.photo,
              ptkp_status: res.data.data.ptkp_status,
              religion: res.data.data.religion,
              status: res.data.data.status,
              section: section,
              total_leave_advance: res.data.data.total_leave_advance,
              total_leave_n: res.data.data.total_leave_n,
              total_leave_n_1: res.data.data.total_leave_n_1,
              weekend_day: res.data.data.weekend_day,
              unit: unit,
              last_attendance_status: res.data.data.last_attendance_status,
              last_attendance_time: res.data.data.last_attendance_time,
              fingerprint_id: res.data.data.fingerprint_id,
              // employee_working_hour: res.data.data.employee_working_hour
              gaji_pokok: res.data.data.gaji_pokok || '0',
              tunjangan_jabatan: res.data.data.tunjangan_jabatan || '0',
              tunjangan_penempatan: res.data.data.tunjangan_penempatan || '0',
              tunjangan_perumahan: res.data.data.tunjangan_perumahan || '0',
              base_bpjs_tk: res.data.data.base_bpjs_tk,
              base_bpjs_pensiun: res.data.data.base_bpjs_pensiun,
              base_bpjs_kesehatan: res.data.data.base_bpjs_kesehatan,
              bpjs_tk_comp: res.data.data.bpjs_tk_comp,
              bpjs_jp_comp: res.data.data.bpjs_jp_comp,
              bpjs_k_comp: res.data.data.bpjs_k_comp,
              bpjs_tk_emp: res.data.data.bpjs_tk_emp,
              bpjs_jp_emp: res.data.data.bpjs_jp_emp,
              bpjs_k_emp: res.data.data.bpjs_k_emp,
              tambahan_bpjs_k:
                res.data.data.tambahan_bpjs_k !== undefined
                  ? res.data.data.tambahan_bpjs_k
                  : 0,
              auto_generate_payroll: res.data.data.auto_generate_payroll,
              is_umr_based_on_company: res.data.data.is_umr_based_on_company,
              is_shift: res.data.data.is_shift,
              umr: res.data.data.umr || '0',
              umr_based_on_company: '0',
              clothing_size: res.data.data.clothing_size,
              account_number: res.data.data.account_number,
              payroll_company: res.data.data.payroll_company,
              resign_date: res.data.data.resign_date,
              working_on_company: res.data.data.working_on_company,
              is_send_payslip: res.data.data.is_send_payslip,
              bpjs_template: res.data.data.bpjs_template,
              tax_object: res.data.data.tax_object,
              bpjs_status: res.data.data.bpjs_status,
              payroll_method: res.data.data.payroll_method,
              can_access_salary: res.data.data.can_access_salary,
              shift_category: res.data.data.shift_category
            }
            this.getUmkByCompany()
            console.log('company>>>', this.result)
          } else {
            this.showMsgDialog('error', res.data.status_msg, false)
          }
          return (this.loading = false)
        })
        .catch((err) => {
          console.log(err)
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          return (this.loading = false)
        })
    },
    getUmkByCompany() {
      const url = `${this.hrsApi}master/universal/config/list?offset=0&filter=[{"company_id":${this.result.company.id}},{"category_id":8}]`
      axios
        .get(url)
        .then((res) => {
          if (res.data.status_code === '00') {
            console.log('get umk>>>', res.data.data)
            this.result.umr_based_on_company =
              res.data.data[0].value !== null
                ? Number(res.data.data[0].value)
                : 0
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    close() {
      // console.log(this.prevRoute.path)
      if (this.prevRoute !== undefined) {
        this.$router.push(this.prevRoute.path)
      } else {
        this.$router.push('/')
      }
    },
    async dropdownCompany() {
      if (this.userLevel !== null) {
        // if (this.userLevel.is_admin !== null) {
        if (this.userLevel.user_user_level !== null) {
          if (
            this.userLevel.user_user_level.allowed_company === null ||
            this.userLevel.user_user_level.allowed_company.length === 0
          ) {
            this.dropdownCompanyApi()
          } else {
            this.dropdown.company =
              this.userLevel.user_user_level.allowed_company
          }
        } else {
          this.dropdownCompanyApi()
        }
        // }
      }
      // else {
      //   this.dropdownCompanyApi()
      // }
    },
    async dropdownCompanyApi() {
      axios
        .get(`${this.eSanqua}master/universal/plant/dropdown`)
        .then((res) => {
          console.log('userLevel dropdown>>>', res.data)
          this.dropdown.company = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    async dropdownDepartment() {
      console.log('this.dropdownCOmpnay', this.result)
      if (this.result === null) {
        return
      }
      await axios
        .get(
          `${this.hrsApi}master/universal/department/dropdown?filter=[{"company_id":${this.result.company.id}}]`
        )
        .then((res) => {
          if (res.data.status_code === '00') {
            // if (this.result.department !== null) {
            //   this.dropdownSection()
            // }
            return (this.dropdown.department = res.data.data)
          }
          return (this.dropdown.department = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdown.department = [])
        })
    },
    // dropdownSection() {
    //   this.dropdown.section = []
    //   axios
    //     .get(
    //       `${this.hrsApi}master/universal/department/dropdown?filter=[{"parent_id":${this.result.department.id}}]`
    //     )
    //     .then(res => {
    //       console.log(res)
    //       if (res.data.status_code === '00') {
    //         return (this.dropdown.section = res.data.data)
    //       }
    //       return (this.dropdown.section = [])
    //     })
    //     .catch(err => {
    //       console.log(err)
    //       return (this.dropdown.section = [])
    //     })
    // },
    dropdownEmployeeStatus() {
      axios
        .get(`${this.hrsApi}master/universal/employeestatus/dropdown`)
        .then((res) => {
          if (res.data.status_code === '00') {
            this.dropdown.status = res.data.data
            return (this.dropdown.employee_status = res.data.data)
          }
          this.dropdown.status = []
          return (this.dropdown.employee_status = [])
        })
        .catch((err) => {
          console.log(err)
          this.dropdown.status = []
          return (this.dropdown.employee_status = [])
        })
    },
    dropdownPosition() {
      axios
        .get(`${this.hrsApi}master/universal/employeelevel/dropdown`)
        .then((res) => {
          if (res.data.status_code === '00') {
            return (this.dropdown.position = res.data.data)
          }
          return (this.dropdown.position = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdown.position = [])
        })
    },
    async dropdownPTKPStatus() {
      await axios
        .get(`${this.hrsApi}master/universal/ptkpstatus/dropdown`)
        .then((res) => {
          if (res.data.status_code === '00') {
            return (this.dropdown.ptkp_status = res.data.data)
          }
          return (this.dropdown.ptkp_status = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdown.ptkp_status = [])
        })
    },
    async getDropdownBpjsTemplate() {
      await axios
        .get(`${this.hrsApi}master/universal/bpjstemplate/dropdown`)
        .then((res) => {
          if (res.data.status_code == '00') {
            return (this.dropdown.bpjs_template = res.data.data)
          }
          return (this.dropdown.bpjs_template = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdown.bpjs_template = [])
        })
    },

    startWatcher() {
      console.log('startWatcher>>>>')
      this.unwatchcompany = this.$watch('result.company', (val) => {
        console.log('company>>>>', val)
        this.dropdownDepartment()
      })
      this.unwatchdepartment = this.$watch('result.department', (val) => {
        console.log('department>>>>', val)
        this.result.department_id = this.result.department.id
      })
      this.unwatchumr = this.$watch('result.umr', (val) => {
        formatValue('umr')
      })

      this.unwatchgajipokok = this.$watch('result.gaji_pokok', (val) => {
        formatValue('gaji_pokok')
      })

      this.unwatchtunjanganjabatan = this.$watch(
        'result.tunjangan_jabatan',
        (val) => {
          formatValue('tunjangan_jabatan')
        }
      )

      this.unwatchtunjanganpenempatan = this.$watch(
        'result.tunjangan_penempatan',
        (val) => {
          formatValue('tunjangan_penempatan')
        }
      )

      this.unwatchtunjanganperumahan = this.$watch(
        'result.tunjangan_perumahan',
        (val) => {
          formatValue('tunjangan_perumahan')
        }
      )
      const self = this
      function formatValue(xParam) {
        var removeChar = self.result[xParam].replace(/[^0-9]/g, '') // this is to remove alphabets and special characters.
        var removeDot = removeChar.replace(/\./g, '') // this is to remove "DOT"
        self.result[xParam] = removeDot
        var formatedNumber = self.result[xParam].replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ','
        )
        self.result[xParam] = formatedNumber
      }
    },
    stopWatcher() {
      this.unwatchcompany()
      this.unwatchdepartment()
      this.unwatchgajipokok()
      this.unwatchumr()
      this.unwatchtunjanganjabatan()
      this.unwatchtunjanganpenempatan()
      this.unwatchtunjanganperumahan()
    },
    triggerWatcher(val) {
      console.log('triggerWatcher>>>>', val)
      if (val === 'stop') {
        this.stopWatcher()
      }
      if (val === 'start') {
        this.startWatcher()

        setTimeout(() => {
          this.result.umr = this.result.umr + ''
          this.result.gaji_pokok = this.result.gaji_pokok + ''
          this.result.tunjangan_jabatan = this.result.tunjangan_jabatan + ''
          this.result.tunjangan_penempatan =
            this.result.tunjangan_penempatan + ''
          this.result.tunjangan_perumahan = this.result.tunjangan_perumahan + ''
        }, 150)
      }
    },
    dropdownTaxObject() {
      this.dropdown.tax_object = [
        {
          id: 1,
          code: '21-100-01'
        },
        {
          id: 2,
          code: '21-100-02'
        },
        {
          id: 3,
          code: '21-100-03'
        },
        {
          id: 4,
          code: '21-100-04'
        },
        {
          id: 5,
          code: '21-100-05'
        },
        {
          id: 6,
          code: '21-100-06'
        },
        {
          id: 7,
          code: '21-100-07'
        },
        {
          id: 8,
          code: '21-100-09'
        },
        {
          id: 9,
          code: '21-100-10'
        },
        {
          id: 10,
          code: '21-100-11'
        },
        {
          id: 11,
          code: '21-100-12'
        },
        {
          id: 12,
          code: '21-100-13'
        },
        {
          id: 13,
          code: '21-401-01'
        },
        {
          id: 14,
          code: '21-401-02'
        }
      ]
    },
    async dropdownShiftCategory() {
      await axios
        .get(`${this.hrsApi}master/universal/shiftcategory/dropdown`)
        .then((res) => {
          if (res.data.status_code === '00') {
            return (this.dropdown.shift_category = res.data.data)
          }
          return (this.dropdown.shift_category = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdown.shift_category = [])
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.v-tab-detail-employee {
  border-top: 1px solid #e1e1e1;
  max-height: 65vh;
  overflow-y: auto;

  &::-webkit-scrollbar {
    // display: none;
    width: 7px;
    background: aquamarine;
  }
  &::-webkit-scrollbar-thumb {
    background: cornflowerblue;
  }
}
</style>
